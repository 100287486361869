import React from 'react';

 class Thankyou extends React.Component {

 
    constructor(props) {
        super(props);
        this.state = {

      };
     
    }
    render(){
        return(
<div>Checkedin</div>
        )
    }
}
export default Thankyou