import React, { Component} from 'react';
import logo from './logo.svg';
import './App.css';
import PaymentForm from './PaymentForm'
import Thankyou from './Thankyou'
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, BrowserRouter } from 'react-router-dom';
import { withRouter } from "react-router";
const browserHistory = createBrowserHistory();

 class App extends Component {
  constructor(props) {
    super(props);

    // Store the previous pathname and search strings
    this.currentPathname = null;
    this.currentSearch = null;
  }
  componentDidMount() {
  //   const { history } = this.props;
    
  //   history.listen((newLocation, action) => {
  //     if (action === "PUSH") {
  //       if (
  //         newLocation.pathname !== this.currentPathname ||
  //         newLocation.search !== this.currentSearch
  //       ) {
  //         // Save new location
  //         this.currentPathname = newLocation.pathname;
  //         this.currentSearch = newLocation.search;

  //         // Clone location object and push it to history
  //         history.push({
  //           pathname: newLocation.pathname,
  //           search: newLocation.search
  //         });
  //       }
  //     } else {
  //       // Send user back if they try to navigate back
  //       history.go(1);
  //     }
  //   });
  }
  render() {
    return (
    <div className="App">
      {/* <BrowserRouter history={browserHistory}>
        <Switch> */}
          <Route exact path="/" component={PaymentForm} />
          {/* <Route path="/thanks" component={Thankyou} /> */}
        {/* </Switch>
      </BrowserRouter> */}
    </div>
  );
}
}

export default App;